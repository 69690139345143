document.addEventListener('turbolinks:load', function () {
    if ($('.teams.show, .invitations.new, .invitations.create').length === 0) {
        return false
    }

    let applyAllSwitch = $('.permission-access .apply-all')
    let selectAllSwitch = $('.permission-access .permission-wrapper .select-all')
    let permissionCheckbox = $(".permission-access .permission-wrapper .access-checkboxes input[type='checkbox']")

    // select all permissions by each module
    $(selectAllSwitch).on('change', function () {
        let moduleType = $(this).data('moduleType')
        if ($(this).prop('checked') === true) {
            $('.' + moduleType).prop('checked', true);
            tickApplyAll()
        } else {
            $('.' + moduleType).prop('checked', false);
            unTickApplyAll()
        }
    });

    // apply all permissions on all module
    $(applyAllSwitch).on('change', function () {
        if ($(this).prop('checked') === true) {
            $(".permission-wrapper input[type='checkbox']").prop('checked', true);
        } else {
            $(".permission-wrapper input[type='checkbox']").prop('checked', false);
        }
    });

    // uncheck select all and apply all when at least one permission is unchecked
    $(permissionCheckbox).on('change', function () {
        if ($(this).prop('checked') === true) {
            tickSelectAll($(this))
        } else {
            unTickSelectAll($(this))
        }
    });

    // tick select all for the module if all the checkboxes inside it are selected
    function tickSelectAll(checkbox) {
        let accessActionSection = $(checkbox).parents('.access-action-section');
        let checkboxesCount = $(accessActionSection).find("input:checkbox").length
        let checkedCheckboxesCount = $(accessActionSection).find("input:checkbox:checked").length
        if (checkedCheckboxesCount === checkboxesCount) {
            let permissionWrapper = $(checkbox).parents('.permission-wrapper');
            let selectAll = $(permissionWrapper).find('.select-all')
            $(selectAll).prop('checked', true)
            tickApplyAll()
        }
    }

    // unselect all for the module if all the checkboxes inside it are selected
    function unTickSelectAll(checkbox) {
        let permissionWrapper = $(checkbox).parents('.permission-wrapper');
        let selectAll = $(permissionWrapper).find('.select-all')
        $(selectAll).prop('checked', false)
        unTickApplyAll()
    }

    // tick apply all if all the permissions are selected
    function tickApplyAll() {
        let selectAllSwitchCount = $(selectAllSwitch).length
        let tickedSelectAllSwitchCount = $('.permission-access .permission-wrapper .select-all:checked').length
        if (selectAllSwitchCount === tickedSelectAllSwitchCount) {
            $(applyAllSwitch).prop('checked', true)
        }
    }

    // uncheck apply all if at least one permission is not selected
    function unTickApplyAll() {
        $(applyAllSwitch).prop('checked', false)
    }

    if ($('.teams .show').length > 0) {
        $(permissionCheckbox).trigger('change')
    }
});